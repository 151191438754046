export const filterOptions = ['Financing', 'Sales', 'Service', 'Installation'];

export const PartnerTypes = [
  {
    partnerType: 'Titanium'
  },
  {
    partnerType: 'Platinum'
  },
  {
    partnerType: 'Gold'
  },
  {
    partnerType: 'Silver'
  }
];
export const privacyPolicy = {
  data: {},
  content: [
    {
      data: {},
      content: [
        {
          data: {},
          marks: [],
          value:
            'I agree to the following terms: Kohler Energy is committed to your privacy. By completing this form and checking the box, you agree to have Kohler Energy or its third party partners contact you via email, text, or phone call (even if you are on the official "Do Not Call" list) to schedule an appointment. Consent is not required for purchase and you can unsubscribe at any time. For more information, see our ',
          nodeType: 'text'
        },
        {
          data: {
            uri: 'https://www.rehlko.com/privacy-policy'
          },
          content: [
            {
              data: {},
              marks: [],
              value: 'Privacy Policy.',
              nodeType: 'text'
            }
          ],
          nodeType: 'hyperlink'
        },
        {
          data: {},
          marks: [],
          value: '\n\n',
          nodeType: 'text'
        }
      ],
      nodeType: 'paragraph'
    },
    {
      data: {},
      content: [
        {
          data: {},
          marks: [],
          value: '',
          nodeType: 'text'
        }
      ],
      nodeType: 'paragraph'
    }
  ],
  nodeType: 'document'
};
